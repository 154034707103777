import PropTypes from "prop-types"
import React from "react"
import logo from "../images/logo_mjleera_blue.png"
import mail from "../images/mail.svg"
import insta from '../images/insta.svg'
import fb from '../images/fb.svg'
import "./layout.css"

const Header = () => (
  <header>
    <div className="header">
    <div><img src={logo} alt="Logo mjleera" 
      className="logo"
      /></div>
    <div className="icons">
      <a href="mailto:hello@mjleera.com">
    <img src={mail} alt="Mail Contact" 
      className="mailIcon"
    /></a>
    <a href="https://www.instagram.com/mjleera/?hl=de">
    <img src={insta} alt="Instagram Profile" 
      className="mailIcon"
    /></a>
    <a href="https://www.facebook.com/mjleera">
    <img src={fb} alt="Facebook Profile" style={{marginRight: '2rem'}}
      className="mailIcon"
    /></a>
    </div>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: `mjleera`,
}

export default Header
