import React from "react"
import team from "../images/team_portrait.svg"


import Header from "./header"
import "./layout.css"



const Layout = () => {
   

  return (
    <>
      <Header/>
      <main className="main">
      <div className="layoutContainer">
        <img src={team} alt="Team mjleera" className="teamImg"/>
        <div className="lead">
        <h1>Mit Herz für dein Projekt.</h1>
        <p>Wir setzen uns mit Herzblut für deine Projekte ein.</p>
        <p>Dabei bewegen wir uns in der Welt der Kommunikation: Websites, Broschüren, Texte aller Sorten und unschlagbare Grafiken und Designs sind unser Ding.</p>
        <p className="more">Möchtest du mehr erfahren? Schreibe uns: <a className="zoom" href="mailto:hello@mjleera.com">hello@mjleera.com</a></p>
        
        </div>
      </div>
      </main>
    </>
  )
}


export default Layout
