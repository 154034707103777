import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="mjleera" description="we want to inspire you, soon!" />
   
  </Layout>
)

export default IndexPage
